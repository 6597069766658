export default {
  okText: '확인',
  closeText: '닫기',
  cancelText: '취소',
  loadingText: '로딩 중..',
  saveText: '저장',
  delText: '삭제',
  resetText: '초기화',
  searchText: '검색',
  queryText: '즉시 조회',
  nextText: '다음',

  inputText: '입력해주세요',
  chooseText: '선택하세요',

  add: '추가',
  update: '업데이트',
  refresh: '새로고침',
  back: '돌아가기',

  light: '밝은 테마',
  dark: '다크 테마',
  countdown: {
    normalText: '인증코드 받기',
    sendText: '{0} 초 후 다시 받기',
  },
};
